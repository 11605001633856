<template>
  <TTView>
    <VRow>
      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить ответ
          </VCardTitle>

          <VCardText>
            Ответ <span class="red--text">{{ answer.id }}</span> будет удален! <br>
            Действительно хотите удалить ответ?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="removeAnswer"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
      <VCol cols="12">
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Ответы
            </VToolbarTitle>

            <VSpacer />

            <VBtn
              class="mr-3"
              exact
              depressed
              color="primary"
              :to="{
                name : Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_CREATE,
                params : {
                  testId,
                  topicId,
                  questionId
                }
              }"
            >
              <VIcon left>
                fal fa-plus
              </VIcon>

              Создать ответ
            </VBtn>
          </VToolbar>
          <VCardText>
            <AnswerTable
              :test-id="testId"
              :topic-id="topicId"
              :question-id="questionId"
              :answers="answers"
              :loading="loadingAnswers"
              @remove:answer="deleteAnswer"
            />
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import AnswerTable from '../../../../../../components/talent/test/topics/questions/answers/AnswerTable.vue';

export default {
  name: 'AnswerList',
  components: { AnswerTable },
  inject: ['Names'],
  data() {
    return {
      answers: [],
      loadingAnswers: false,
      answer: {},
      confirmDialog: false,
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
    questionId() {
      return this.$route.params.questionId;
    },
  },
  async created() {
    await this.getAnswers(this.testId, this.questionId);
  },
  methods: {
    async getAnswers(testId, questionId) {
      this.loadingAnswers = true;
      try {
        this.answers = (await this.$di.api.Talent.choicesGet({ testId, questionId })) || [];
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.loadingAnswers = false;
      }
    },

    async removeAnswer() {
      try {
        const {
          answer: { id: answerId },
          testId,
          questionId,
        } = this;
        const data = { testId, questionId, answerId };

        await this.$di.api.Talent.choiceDelete(data);
        await this.getAnswers(this.testId, this.questionId);

        this.resetAnswerEdit();

        this.$di.notify.snackSuccess('Ответ успешно удалён');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    resetAnswerEdit() {
      this.confirmDialog = false;
      this.answer = {};
    },

    handleCancel() {
      this.resetAnswerEdit();
    },

    deleteAnswer(item) {
      this.confirmDialog = true;
      this.answer = { ...item };
    },
  },
};
</script>
