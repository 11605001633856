var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить ответ ")]),_c('VCardText',[_vm._v(" Ответ "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.answer.id))]),_vm._v(" будет удален! "),_c('br'),_vm._v(" Действительно хотите удалить ответ? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.removeAnswer}},[_vm._v(" Да ")])],1)],1)],1),_c('VCol',{attrs:{"cols":"12"}},[_c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1),_c('VToolbarTitle',[_vm._v(" Ответы ")]),_c('VSpacer'),_c('VBtn',{staticClass:"mr-3",attrs:{"exact":"","depressed":"","color":"primary","to":{
              name : _vm.Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_CREATE,
              params : {
                testId: _vm.testId,
                topicId: _vm.topicId,
                questionId: _vm.questionId
              }
            }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Создать ответ ")],1)],1),_c('VCardText',[_c('AnswerTable',{attrs:{"test-id":_vm.testId,"topic-id":_vm.topicId,"question-id":_vm.questionId,"answers":_vm.answers,"loading":_vm.loadingAnswers},on:{"remove:answer":_vm.deleteAnswer}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }