<template>
  <VDataTable
    :headers="headers"
    :items="answers"
    :loading="loading"
  >
    <template #item.originalText="{ item }">
      {{ item.originalText || 'Нет названия' }}
    </template>

    <template #item.right="{ item }">
      <template v-if="item.right">
        <VIcon color="success">
          fal fa-check-circle
        </VIcon>
      </template>
    </template>

    <template #item.actions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="blue"
            class="mr-2"
            v-bind="attrs"
            :to="{
              name : Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER,
              params : {
                topicId,
                testId,
                questionId,
                answerId : item.id
              }
            }"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр ответа</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="warning"
            :to="{
              name : Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_EDIT,
              params : {
                topicId,
                testId,
                questionId,
                answerId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование ответа</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="red"
            v-bind="attrs"
            v-on="on"
            @click="$emit('remove:answer', item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление ответа</span>
      </VTooltip>
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'AnswerTable',
  inject: ['Names'],
  props: {
    testId: {
      type: [Number, String],
      required: true,
    },
    topicId: {
      type: [Number, String],
      required: true,
    },
    questionId: {
      type: [Number, String],
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Текст (оригинальный)',
          value: 'originalText',
        },
        {
          text: 'Верный',
          value: 'right',
        },
        {
          text: 'Действия',
          align: 'right',
          value: 'actions',
        },
      ],
    };
  },
};
</script>
